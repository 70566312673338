<template>
  <a-spin :spinning="spinning">
    <a-card :border="false">
      <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="折扣名称">
                <a-input v-model="searchData.rebateName" placeholder="折扣名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="大区事务所">
                <a-cascader
                  :options="dealerList"
                  v-model="regionalOffice"
                  change-on-select
                  placeholder="请选择大区/事务所"
                  @change="onChange"
                  allowClear
                  :field-names="{ label: 'title', value: 'id', children: 'children' }"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="经销商名称">
                <a-input v-model="searchData.dealerName" allowClear placeholder="经销商名称"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="折扣时间">
                <DateRange
                  :startTime.sync="searchData.startTimeString"
                  :endTime.sync="searchData.endTimeString"
                  allowClear
                ></DateRange>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-item label="状态">
                <a-select placeholder="请选择" v-model="searchData.usedStatus" allowClear>
                  <a-select-option :value="''">全部</a-select-option>
                  <a-select-option :value="0">未开始</a-select-option>
                  <a-select-option :value="10">正常</a-select-option>
                  <!--                <a-select-option :value="20">已使用</a-select-option>-->
                  <a-select-option :value="30">已过期</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="费用类型">
                <DictSelect field="costType" :value.sync="searchData.costType" placeholder="优惠费用类型"></DictSelect>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-item label="是否延期">
                <a-select placeholder="请选择" v-model="searchData.flagApply" allowClear>
                  <a-select-option :value="''">全部</a-select-option>
                  <a-select-option :value="false">未延期</a-select-option>
                  <a-select-option :value="true">已延期</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                <a-button type="default" @click="reset()" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div>
          <a-button v-if="isAdd" type="primary" @click="toHandler('add')">新增</a-button>
          <a-button v-if="selectedRows.length == 1" type="primary" @click="toHandler('check')">查看</a-button>
          <!--        <a-button type="primary" @click="toHandler('edit')">编辑</a-button>-->
          <a-button v-if="isCopy && selectedRowKeys.length == 1" type="primary" @click="toHandler('editCody')"
            >复制</a-button
          >
          <a-button
            v-if="isRebateApply && selectedRowKeys.length > 0"
            type="primary"
            @click="typeChargeApply"
            icon="sync"
            >延时申请</a-button
          >
          <a-button
            v-if="isEditFile && selectedRowKeys.length == 1 && selectedRows[0].status == 1"
            type="primary"
            @click="fileEdit"
            icon="edit"
            >修改附件</a-button
          >
          <!--        v-if="selectedRowKeys.length > 0  && selectedRowKeys[0].verifyStatus == 2"-->
          <a-button
            v-if="isRecord && selectedRowKeys.length == 1"
            type="primary"
            @click="toHandler('checkDetail')"
            icon="copy"
            >使用记录</a-button
          >
          <a-button
            v-if="isLog && selectedRowKeys.length == 1"
            type="primary"
            @click="toHandler('checkLog')"
            icon="file"
            >操作日志</a-button
          >
          <a-button v-if="isDelete && selectedRowKeys.length == 1" type="primary" @click="toHandler('del')"
            >删除</a-button
          >
          <downLoad
            v-if="isExport"
            method="post"
            @downLoadDone="downLoadDone"
            @startDownLoad="startDownLoad"
            api="/api/dealer/dealer/dealerRebate/excelExportRebate"
            :params="exportSearchData"
            name="折扣金额.xls"
            >导出</downLoad
          >
        </div>
        <!-- 数据表格 -->
        <a-table
          :loading="tableLoading"
          :row-selection="rowSelection"
          :rowKey="(record) => record.id"
          :columns="columns"
          :data-source="tableData"
          bordered
          :pagination="page"
          @change="changeTable"
          :customRow="changeTableRow"
          :rowClassName="rowClassName"
          :scroll="{ x: 1600 }"
        >
          <span slot="flagEnable" slot-scope="text, record">
            <a-switch
              v-model="record.flagEnable"
              :disabled="record.usedStatus == 0 || record.usedStatus == 20 || record.usedStatus == 30"
              @change="onChangeStatus(record)"
            />
          </span>
          <span slot="startTime" slot-scope="text, row">
            <span>{{ row.startTime }} ~ {{ row.endTime }}</span>
          </span>
          <span slot="usedStatus" slot-scope="text">
            <a-tag v-if="text == 0" color="#108ee9">未开始</a-tag>
            <a-tag v-if="text == 10" color="#67c23a">正常</a-tag>
            <a-tag v-if="text == 20" color="#ff7875">已使用</a-tag>
            <a-tag v-if="text == 30" color="#909399">已过期</a-tag>
          </span>
          <span slot="costType" slot-scope="text">
            <a-tag v-if="text == 1">返利</a-tag>
            <a-tag v-if="text == 2">推广费</a-tag>
          </span>
          <!--        <span slot="status" slot-scope="text">-->
          <!--          <a-tag v-if="text == 0" color="#909399">已过期</a-tag>-->
          <!--          <a-tag v-if="text == 1" color="#67c23a">正常</a-tag>-->
          <!--          <a-tag v-if="text == 2" color="#108ee9">未到使用时间</a-tag>-->
          <!--          <a-tag v-if="text == 3" color="#ff7875">即将过期</a-tag>-->
          <!--        </span>-->
          <span slot="rebateFilesNum" slot-scope="text, record">
            <span v-if="text <= 0">未上传附件</span>
            <a-button v-if="text > 0" style="width: 80px" icon="eye" @click.stop="getFileList(record)">查看</a-button>
          </span>
          <span slot="rebateAmount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="remainingAmount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="flagApply" slot-scope="text">
            <a-tag v-if="text == false" color="#909399">未延期</a-tag>
            <a-tag v-if="text == true" color="#67c23a">已延期</a-tag>
          </span>
          <span slot="defaultEndTime" slot-scope="text, row" v-if="row.flagApply == true">
            {{ row.defaultEndTime }}
          </span>
        </a-table>
        <!-- 编辑表单 -->
        <DealerRebate-edit-modal ref="DealerRebateEditModal" @reload="getData"></DealerRebate-edit-modal>
        <DealerRebateCodytModal ref="DealerRebateCodytModal" @reload="getData"></DealerRebateCodytModal>
        <UsedDetailed ref="UsedDetailed"> </UsedDetailed>
        <OperateLog ref="OperateLog"></OperateLog>
        <RebateFileListModal ref="RebateFileListModal"></RebateFileListModal>
        <DealerRebateCheckModal ref="DealerRebateCheckModal"></DealerRebateCheckModal>

        <a-modal
          v-model="visibleCharge"
          title="折扣金额延时申请"
          @ok="handleOkCharge"
          @cancel="handleCanceCharge"
          :maskClosable="false"
          width="40%"
          :confirm-loading="confirmLoading"
        >
          <a-form-model
            ref="formCharge"
            :model="rowDataCharge"
            :rules="formRule"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 15 }"
          >
            <a-form-model-item label="延时至" prop="applyTime">
              <DatePicker :startTime.sync="rowDataCharge.applyTime" :currentTime.sync="currentTime"></DatePicker>
            </a-form-model-item>
            <a-form-model-item label="备注">
              <a-textarea v-model="rowDataCharge.remarkCharge"></a-textarea>
            </a-form-model-item>
          </a-form-model>
        </a-modal>

        <a-modal
          v-model="visibleFile"
          title="折扣附件修改"
          @ok="handleOkFile"
          @cancel="handleCanceFile"
          :maskClosable="false"
          width="40%"
          :confirm-loading="confirmLoading"
        >
          <a-form-model
            ref="formFile"
            :model="rowDataFile"
            :rules="formRule"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 15 }"
          >
            <a-form-model-item label="上传附件">
              <QiniuUpload type="file" :value.sync="filesUrl" :size="5"></QiniuUpload>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import { columns } from './components/colums.js'
import DealerRebateEditModal from './components/DealerRebateEditModal.vue'
import DealerRebateCheckModal from '@/views/dealer_rebate/components/DealerRebateCheckModal.vue'

import DealerRebateCodytModal from './components/DealerRebateCodytModal.vue'
import RebateFileListModal from './components/RebateFileListModal.vue'

import UsedDetailed from '@/views/dealer_rebate/UsedDetailed'
import OperateLog from '@/views/dealer_rebate/OperateLog'
import {
  delDealerRebate,
  listDealerRebate,
  selectByIdDealerRebate,
  addRebateApplyInfo,
  addDealerRebateFiles,
} from './api/DealerRebateApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerRebate',
  components: {
    DealerRebateEditModal,
    DealerRebateCodytModal,
    DealerRebateCheckModal,
    RebateFileListModal,
    UsedDetailed,
    OperateLog,
  },
  data() {
    return {
      spinning: false,
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      isAdd: checkPermission('dealer:rebate:add'),
      isDelete: checkPermission('dealer:rebate:delete'),
      isExport: checkPermission('dealer:rebate:export'),
      isRebateApply: checkPermission('dealer:rebate:rebate:apply'),
      isEditFile: checkPermission('dealer:rebate:edit:file'),
      isCopy: checkPermission('dealer:rebate:copy'),
      isRecord: checkPermission('dealer:rebate:record'),
      isLog: checkPermission('dealer:rebate:operate:log'),
      selectedRowKeys: [],
      selectedRows: [],
      dealerList: [],
      regionalOffice: [],
      confirmLoading: false,
      visibleCharge: false,
      applyTime: '',
      rowDataCharge: {},
      currentTime: '',
      formRule: {
        applyTime: [{ required: true, message: '请选择延时时间', trigger: 'change' }],
      },
      filesUrl: '',
      rowDataFile: {},
      visibleFile: false,
      distributor_name: this.$route.params.dealerName,
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    exportSearchData() {
      return {
        ...this.searchData,
        ids: this.selectedRowKeys,
      }
    },
  },
  mounted() {
    if (this.distributor_name) {
      this.searchData.dealerName = this.distributor_name
    }
    this.getData()
  },
  methods: {
    startDownLoad() {
      this.spinning = true
    },
    downLoadDone() {
      this.spinning = false
    },
    getFileList(row) {
      this.$refs.RebateFileListModal.isShow(row)
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    /**
     * 获取表格数据
     */
    getData(num) {
      if (!num) {
        this.selectedRowKeys = []
        this.selectedRows = []
      }
      this.tableLoading = true
      listDealerRebate({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        // 获取大区事务所经销商数据
        this.dealerList = res.body
      })
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },

    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    // 修改状态
    onChangeStatus(row) {
      this.axios
        .post('/api/dealer/dealer/dealerRebate/status/' + row.id)
        .then((res) => {
          if (res.code) {
            this.$message.success(res.message)
          }
        })
        .catch((err) => {})
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData(1)
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerRebateEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerRebateEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'editCody':
          _this.$refs.DealerRebateCodytModal.setRowData(_this.selectedRows[0], 'editCody')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              console.log(_this.selectedRows[0])
              const res = await delDealerRebate(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'checkDetail':
          this.$refs.UsedDetailed.setRowData(this.selectedRows[0])
          break
        case 'checkLog':
          this.$refs.OperateLog.setRowData(this.selectedRows[0])
          break
        case 'check':
          this.$refs.DealerRebateCheckModal.isShow(this.selectedRows[0])
          break
      }
    },

    // 类型转换申请
    typeChargeApply() {
      // let _this=this;
      if (this.selectedRows.length === 0) {
        this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      const myDate = new Date()
      this.visibleCharge = true
      this.currentTime = myDate.toLocaleDateString().split('/').join('-')
      this.$set(this.rowDataCharge, 'applyTime', this.currentTime)
      // this.currentTime = this.selectedRows[0].endTime;
      // this.rowDataCharge.applyTime = this.selectedRows[0].endTime
      // this.$set(this.rowDataCharge,'applyTime',this.selectedRows[0].endTime)
    },

    // 附件修改
    fileEdit() {
      // let _this=this;
      if (this.selectedRows.length === 0) {
        this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }

      selectByIdDealerRebate(this.selectedRows[0].id).then((res) => {
        this.filesUrl = res.body.files
          ? res.body.files
              .map((e) => {
                return e.fileUrl
              })
              .toString()
          : ''
      })
      this.visibleFile = true
      this.$set(this.rowDataCharge, 'applyTime', this.selectedRows[0].endTime)
      this.$set(this.rowDataFile, 'filesUrl', this.filesUrl)
    },

    handleOkCharge() {
      this.$refs.formCharge.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.confirmLoading = true
        let rebateIds = []
        this.selectedRows.filter((e) => {
          rebateIds.push(e.id)
        })
        // let rebateId = this.selectedRows[0].id
        let dealerId = this.selectedRows[0].dealerId
        const params = {
          applyTime: this.rowDataCharge.applyTime,
          remark: this.rowDataCharge.remarkCharge,
          rebateIds: rebateIds,
          dealerId: dealerId,
        }
        addRebateApplyInfo(params)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.getData()
            } else {
              this.$message.error(res.message)
            }
          })
          .finally(() => {
            this.confirmLoading = false
            this.visibleCharge = false
            this.rowDataCharge = {}
            this.currentTime = ''
          })
      })
    },

    handleOkFile() {
      this.$refs.formFile.validate(async (valid) => {
        if (!valid) {
          return
        }
        if (!this.filesUrl) {
          return this.$message.warning('请上传附件')
        }
        this.rowDataFile.files = this.filesUrl.split(',').map((item) => {
          return {
            fileUrl: item,
          }
        })
        this.confirmLoading = true
        let rebateId = this.selectedRows[0].id
        const params = {
          files: this.rowDataFile.files,
          id: rebateId,
        }
        addDealerRebateFiles(params)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.getData()
            } else {
              this.$message.error(res.message)
            }
          })
          .finally(() => {
            this.confirmLoading = false
            this.visibleFile = false
            this.rowDataFile = {}
            this.filesUrl = ''
          })
      })
    },

    handleCanceCharge() {
      this.confirmLoading = false
      this.visibleCharge = false
      this.rowDataCharge = {}
      this.currentTime = ''
    },

    handleCanceFile() {
      this.confirmLoading = false
      this.visibleFile = false
      this.rowDataFile = {}
      this.filesUrl = ''
    },
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
